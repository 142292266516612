
import React, { useEffect, useState } from 'react'
import { useApp } from '@/contexts/appContext';
import http from '@/lib/http';
import { BadRequestError, handleResponseError } from '@/utils/errors';
import { useToast } from '@chakra-ui/react';
import { MdOutlineDirections } from "react-icons/md";
import { ImSpinner8 } from 'react-icons/im';
import { numberFormat } from '@/utils/helpers';
import IframePopup from '@/components/IframePopup';
import MobileMenu from '@/components/MobileMenu';
import { FaCreditCard } from 'react-icons/fa6';

function CardEmbed() {

    const toast = useToast();
    const { info, setInfo } = useApp();
    const [isLoading, setIsLoading] = useState(false);
    const [url, setUrl] = useState("");

    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    const [isPaymentRunning, setIsPaymentRunning] = useState(false);

    function checkStatus(stopAfter: number = 6) {
        if (stopAfter <= 0) {
            console.log("done");
            setIsLoading(false);
            toast({
                title: 'Error',
                description: "Payment timeout",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        setTimeout(async () => {
            try {
                const resp = await http("wallet/collection/info", { order_reference_code: info?.order_reference_code });
                setInfo((e) => e !== null ? ({ ...e, payment_status: resp.data.payment_status }) : null);
                if (resp.data.payment_status === "Pending") {
                    checkStatus(stopAfter - 1);
                }
            } catch (error) {
                console.log(error);
                handleResponseError(error, toast);
                checkStatus(stopAfter - 1);
            }
        }, 5000);
    }

    // check for payment status if user has closed partner modal
    useEffect(() => {
        if (!isPaymentModalOpen && isPaymentRunning) {
            // alert("payment modal closed");
            checkStatus();
            setIsPaymentRunning(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPaymentRunning, isPaymentModalOpen])

    // start payment
    async function initiatePartnerPay() {
        setIsLoading(true);
        try {
            const resp = await http("wallet/collection/method/card-embed", { order_reference_code: info?.order_reference_code });
            setUrl(resp.data.embed_url)
            await PayWithPartnerPopUp(resp.data.embed_url ? resp.data.embed_url : "");
            // await PayWithPartnerPopUp("https://bluefoxs.capital");
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            handleResponseError(error, toast);
        }
    }

    // trigger modal
    async function PayWithPartnerPopUp(url: string) {
        if (url.length > 0) {
            if (!isPaymentRunning) {
                setIsPaymentRunning(true);
                setIsPaymentModalOpen(true);
            } else {
                throw new BadRequestError("Payment is already running");
            }
        } else {
            throw new BadRequestError("Error generating payment link.");
        }
    }

    return (
        <>
            {isPaymentModalOpen && <IframePopup url={url} setIsPaymentModalOpen={setIsPaymentModalOpen} />}
            <div>
                <div className='mb-5 md:mb-10'>
                    <div className='flex justify-between items-center bg-purple-50 md:bg-inherit border-b-2 md:border-b-0 md:p-0 p-4'>
                        <div className='p-4 md:bg-purple-900 md:bg-opacity-45 rounded-full'>
                            <img src={'/moipayway-icon.png'} className='w-6' alt='icon' />
                        </div>
                        <div className='flex flex-col items-end text-[11px] md:text-sm pr-2'>
                            <div className='font-normal'>{info?.merchant.name}</div>
                            <div className='font-bold md:font-normal'>{info?.user.email}</div>
                            <div className='font-bold'>{info?.currency.main.toUpperCase()} {numberFormat(info ? info.amount.main : "")}</div>
                        </div>
                    </div>
                </div>

                <div className='flex items-center gap-2 px-5 mb-6 md:hidden'>
                    <div className='p-2 rounded-full flex justify-center items-center bg-purple-900 bg-opacity-90'>
                        <FaCreditCard className='w-3 h-3 text-neutral-100' />
                    </div>
                    <div className='text-neutral-900 font-bold text-sm'>Card</div>
                </div>

                <div className='flex justify-center w-full mb-[200px]'>
                    <div className='w-[300px] pt-[50px]'>
                        <center>
                            <div className='mb-5 p-5 rounded-full bg-neutral-100 inline-block text-purple-900'>
                                <MdOutlineDirections className='w-8 h-8' />
                            </div>
                            <div className='text-md mb-4'>You will be redirected to our partner page to complete card payment</div>
                            <button className='px-5 py-2 bg-purple-900 text-neutral-50 text-sm' disabled={isLoading} onClick={() => initiatePartnerPay()}>
                                {
                                    isLoading ?
                                        <div className='px-7'><ImSpinner8 className='animate-spin w-4 h-4' /></div> :
                                        <span>Continue</span>
                                }
                            </button>
                        </center>
                    </div>
                </div>
                <div className='block md:hidden'>
                    <MobileMenu />
                </div>
            </div>
        </>
    )
}

export default CardEmbed