"use client"

import React, { createContext, useContext, useEffect, useState } from "react"
import { IAppContext, IPaymentInfo, IPaymentStatus } from "@/types/global.types";

export const AppContext = createContext<IAppContext | undefined>(undefined);

function AppProvider({ children }: { children: React.ReactNode }) {

  const [info, setInfo] = useState<IPaymentInfo | null>(null);
  const [isFetching, setIsFetching] = useState(true);
  const [status, setStatus] = useState<IPaymentStatus>("Pending");
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, [])

  return (
    <AppContext.Provider value={{
      isMounted,
      info,
      setInfo,
      isFetching,
      setIsFetching,
      status,
      setStatus
    }}>
      {children}
    </AppContext.Provider>
  )
}

export function useApp() {
  return useContext<IAppContext | undefined>(AppContext)!;
}

export default AppProvider