import React, { useEffect } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import AppProvider from "@/contexts/appContext";

function Providers({ children }: {
    children: React.ReactNode
}) {
    const [mounted, setMounted] = React.useState(false);
    useEffect(() => setMounted(true), []);
    return (
        <ChakraProvider>
            <AppProvider>
                {mounted && children}
            </AppProvider>
        </ChakraProvider>
    )
}

export default Providers