import MobileMenu from '@/components/MobileMenu';
import { useApp } from '@/contexts/appContext';
import { numberFormat } from '@/utils/helpers';
import classNames from 'classnames';
import React from 'react'
import { FaSlackHash } from 'react-icons/fa';
import { GiPadlock } from "react-icons/gi";

function Ussd() {

    const { info } = useApp();

    return (
        <>
            <div className='mb-5 md:mb-10'>
                <div className='flex justify-between items-center bg-purple-50 md:bg-inherit border-b-2 md:border-b-0 md:p-0 p-4'>
                    <div className='p-4 md:bg-purple-900 md:bg-opacity-45 rounded-full'>
                        <img src={'/moipayway-icon.png'} className='w-6' alt='icon' />
                    </div>
                    <div className='flex flex-col items-end text-[11px] md:text-sm pr-2'>
                        <div className='font-normal'>{info?.merchant.name}</div>
                        <div className='font-bold md:font-normal'>{info?.user.email}</div>
                        <div className='font-bold'>{info?.currency.main.toUpperCase()} {numberFormat(info ? info.amount.main : "")}</div>
                    </div>
                </div>
            </div>

            <div className='flex items-center gap-2 px-5 mb-6 md:hidden'>
                <div className='p-2 rounded-full flex justify-center items-center bg-purple-900 bg-opacity-90'>
                    <FaSlackHash className='w-3 h-3 text-neutral-100' />
                </div>
                <div className='text-purple-900 font-bold text-sm'>USSD</div>
            </div>

            <div className='w-full flex justify-center text-xs md:text-sm mb-4 md:mb-8'>
                <div className='border-b-2 border-b-purple-900 p-1'><center>Select your bank to begin payment</center></div>
            </div>

            <div className='w-full flex justify-center mb-[100px]'>
                <div className='w-full md:w-[60%]'>
                    <center>
                        <div className='w-[80%] md:w-full bg-purple-50 p-2 mb-5 border-2 border-purple-100'>
                            <div className='text-xs font-bold text-neutral-800 text-opacity-85 pl-2 text-left'>SELECT BANK</div>
                            <select className='outline-none w-full p-2 bg-transparent text-neutral-900 text-opacity-50 text-sm md:text-[15px]' >
                                <option value="">Click to select</option>
                                <option value="Access bank">Access bank</option>
                                <option value="Kuda MFB">Kuda MFB</option>
                                <option value="UBA">UBA</option>
                                <option value="First bank">First bank</option>
                                <option value="Eco bank">Eco bank</option>
                                <option value="Fidelity bank">Fidelity bank</option>
                                <option value="Palmpay LTD">Palmpay LTD</option>
                                <option value="VFD Micro finance Bank">VFD Micro finance Bank</option>
                            </select>
                        </div>
                    </center>

                    <div className='mb-10'>
                        <center>
                            <button type="button" className='w=[60%] md:w-full bg-purple-900 text-neutral-50 rounded-md p-3 text-sm font-bold'>Pay {info?.currency.main.toUpperCase()} {numberFormat(info!.amount.main)}</button>
                        </center>
                    </div>

                    <div className='flex justify-center'>
                        <div className='flex justify-between gap-3'>
                            <GiPadlock className='w-4 h-4' />
                            <div className='text-xs'>Secured by <b>MoiPayWay</b></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='block md:hidden'>
                <MobileMenu />
            </div>
        </>
    )
}

export default Ussd