import React, { useEffect, useState } from 'react'
import { useApp } from '@/contexts/appContext';
import http from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
import { useToast } from '@chakra-ui/react';
import { GiPadlock } from "react-icons/gi";
import { ImSpinner8 } from 'react-icons/im';
import { TbExclamationCircle } from "react-icons/tb";
import { IDynamicVirtualAccount } from '@/types/global.types';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import CountdownTimer from '@/components/CountdownTimer';
import { calculateExpiryTime, numberFormat } from '@/utils/helpers';
import MobileMenu from '@/components/MobileMenu';
import { GrTransaction } from 'react-icons/gr';

function BankTransfer() {

    const toast = useToast();
    const [tab, setTab] = useState<"paid" | "info">("info");
    const { info, setInfo } = useApp();
    const [isFetchingDynamicVirtualAccount, setIsFetchingDynamicVirtualAccount] = useState(true);
    const [dynamicVirtualAccount, setDynamicVirtualAccount] = useState<IDynamicVirtualAccount | null>(null);

    async function fetchDynamicVirtualAccount() {
        try {
            const resp = await http("wallet/collection/method/dynamic-virtual-account", { order_reference_code: info?.order_reference_code });
            setDynamicVirtualAccount(resp.data ? resp.data : null);
            setIsFetchingDynamicVirtualAccount(false);
        } catch (error) {
            console.log(error);
            handleResponseError(error, toast);
            setIsFetchingDynamicVirtualAccount(false);
        }
    }

    function checkStatus(stopAfter: number = 20) {
        if (stopAfter <= 0) {
            console.log("done");
            return;
        }
        setTimeout(async () => {
            try {
                const resp = await http("wallet/collection/info", { order_reference_code: info?.order_reference_code });
                setInfo((e) => e !== null ? ({ ...e, payment_status: resp.data.payment_status }) : null);
                if (resp.data.payment_status === "Pending") {
                    checkStatus(stopAfter - 1);
                }
            } catch (error) {
                console.log(error);
                handleResponseError(error, toast);
                checkStatus(stopAfter - 1);
            }
        }, 5000);
    }

    async function confirmPayment() {
        setTab("paid");
        checkStatus();
    }

    useEffect(() => {
        fetchDynamicVirtualAccount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className='mb-5 md:mb-10'>
                <div className='flex justify-between items-center bg-purple-50 md:bg-inherit border-b-2 md:border-b-0 md:p-0 p-4'>
                    <div className='p-4 md:bg-purple-900 md:bg-opacity-45 rounded-full'>
                        <img src={'/moipayway-icon.png'} className='w-6' alt='icon' />
                    </div>
                    <div className='flex flex-col items-end text-[11px] md:text-sm pr-2'>
                        <div className='font-normal'>{info?.merchant.name}</div>
                        <div className='font-bold md:font-normal'>{info?.user.email}</div>
                        <div className='font-bold'>{info?.currency.main.toUpperCase()} {numberFormat(info ? info.amount.main : "")}</div>
                    </div>
                </div>
            </div>

            <div className='flex items-center gap-2 px-5 mb-6 md:hidden'>
                <div className='p-2 rounded-full flex justify-center items-center bg-purple-900 bg-opacity-90'>
                    <GrTransaction className='w-3 h-3 text-neutral-100' />
                </div>
                <div className='text-neutral-900 font-bold text-sm'>Bank Transfer</div>
            </div>

            {
                !isFetchingDynamicVirtualAccount && dynamicVirtualAccount ?
                    <>
                        {
                            tab === "info" ?
                                <>
                                    <div className='w-full flex justify-center text-xs md:text-sm mb-4 md:mb-8'>
                                        <div className='border-b-2 border-b-purple-900 p-1'><center>Proceed to your bank app <br /> to complete this transfer</center></div>
                                    </div>

                                    <div className='w-full flex justify-center text-sm mb-2'>
                                        <CountdownTimer duration={dynamicVirtualAccount.meta.duration} durationUnit={dynamicVirtualAccount.meta.duration_unit as any} />
                                    </div>

                                    <div className='w-full flex justify-center'>
                                        <div className='w-full md:w-[60%]'>
                                            <div className='w-full bg-purple-50 px-6 py-8 mb-5'>
                                                <div className='mb-6'>
                                                    <div className='text-[10px] text-neutral-800 text-opacity-85'>AMOUNT</div>
                                                    <div className='font-bold text-neutral-800 text-opacity-90'>{dynamicVirtualAccount.meta.currency.toUpperCase()} {dynamicVirtualAccount.meta.amount}</div>
                                                </div>
                                                <div className='mb-6'>
                                                    <div className='text-[10px] text-neutral-800 text-opacity-85'>ACCOUNT NUMBER</div>
                                                    <div className='font-bold text-neutral-800 text-opacity-90'>{dynamicVirtualAccount.meta.account_no}</div>
                                                </div>
                                                <div className='mb-6'>
                                                    <div className='text-[10px] text-neutral-800 text-opacity-85'>BANK NAME</div>
                                                    <div className='font-bold text-neutral-800 text-opacity-90'>{dynamicVirtualAccount.meta.bank_name}</div>
                                                </div>
                                                <div className='mb-8'>
                                                    <div className='text-[10px] text-neutral-800 text-opacity-85'>BENEFICIARY</div>
                                                    <div className='font-bold text-neutral-800 text-opacity-90'>{dynamicVirtualAccount.meta.account_name}</div>
                                                </div>
                                                <hr />
                                                <div className='px-2 flex justify-between gap-3 w-full'>
                                                    <TbExclamationCircle className='w-4 h-4' />
                                                    <div className='flex-1 text-[10px]'>This account was generated for this transaction only. It is only valid for this specific transaction and will expire by {calculateExpiryTime(dynamicVirtualAccount.meta.duration, dynamicVirtualAccount.meta.duration_unit as any)}</div>
                                                </div>
                                            </div>

                                            <div className='mb-4 md:mb-10'>
                                                <center>
                                                    <button onClick={() => confirmPayment()} type="button" className='w-[60%] md:w-full bg-purple-900 text-neutral-50 rounded-md p-3 text-xs font-bold'>I have made this bank transfer</button>
                                                </center>
                                            </div>

                                            <div className='flex justify-center'>
                                                <div className='flex justify-between gap-3'>
                                                    <GiPadlock className='w-4 h-4' />
                                                    <div className='text-xs'>Secured by <b>MoiPayWay</b></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='block md:hidden'>
                                        <MobileMenu />
                                    </div>
                                </> :
                                <>
                                    <div className='flex justify-center w-full'>
                                        <div className='w-[300px] pt-[50px]'>
                                            <center>
                                                <div className='mb-5 p-5 rounded-full bg-neutral-100 inline-block text-purple-900'>
                                                    <ImSpinner8 className='w-8 h-8 animate-spin' />
                                                </div>
                                                <div className='text-md mb-4'>Payment is being confirmed. please do not refresh this page</div>
                                            </center>
                                        </div>
                                    </div>
                                </>

                        }
                    </> :
                    <>
                        <div className='w-full flex justify-center h-[200px] items-center'>
                            <AiOutlineLoading3Quarters className='w-8 h-8 text-purple-700 animate-spin mb-10' />
                        </div>
                    </>
            }
        </>
    )
}

export default BankTransfer