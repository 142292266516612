
import React from 'react'
import { BiError } from "react-icons/bi";

function PageNotFound() {

    return (
        <>
            <div className='w-full flex h-screen justify-center bg-neutral-50 dark:bg-neutral-900 text-neutral-800 dark:text-neutral-100 items-center'>
                <div className='-mt-[100px] lg:-mt-[130px]'>
                    <center className=''>
                    <BiError className='w-12 h-12 text-purple-700 mb-10' />
                        <div className="mb-2 font-bold">Invalid Request</div>
                        <div className="text-xs">Page not found or the transaction request is invalid</div>
                        {/* {
                            type === "hosted" ?
                            <button onClick={() => redirect()} className="text-xs">Go back to <span className="text-purple-700">{info.merchant.name}</span></button> :
                            <button onClick={() => redirect()} className="text-xs text-purple-700">Close</button>
                        } */}
                    </center>
                </div>
            </div>
        </>
    )
}

export default PageNotFound