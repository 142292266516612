import React, { useState, useEffect } from 'react';

interface Props {
  duration: number;
  durationUnit: 'seconds' | 'minutes' | 'hours';
}

const CountdownTimer: React.FC<Props> = ({ duration, durationUnit }) => {
  const [timeLeft, setTimeLeft] = useState(convertToSeconds(duration, durationUnit));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTimeLeft => {
        if (prevTimeLeft <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTimeLeft - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [duration, durationUnit]);

  const formatTime = (time: number): string => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  function convertToSeconds(time: number, unit: 'seconds' | 'minutes' | 'hours'): number {
    switch (unit) {
      case 'seconds':
        return time;
      case 'minutes':
        return time * 60;
      case 'hours':
        return time * 3600;
      default:
        throw new Error('Invalid duration unit');
    }
  }

  return (
    <div className='flex gap-2'>
        <div>Expires in</div>
      <p className='font-bold'>{formatTime(timeLeft)}</p>
    </div>
  );
};

export default CountdownTimer;
