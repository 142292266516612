
import React from 'react'

import { numberFormat } from '@/utils/helpers';
import MobileMenu from '@/components/MobileMenu';
import { useApp } from '@/contexts/appContext';
import { GiPayMoney } from "react-icons/gi";
import { FaCreditCard } from 'react-icons/fa6';
import { FaSlackHash } from 'react-icons/fa';
import { GrTransaction } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';

function Home() {

    const { info } = useApp();
    const navigate = useNavigate();

    return (
        <>
            <div>
                <div className='mb-0 md:mb-10'>
                    <div className='flex justify-between items-center bg-purple-50 md:bg-inherit border-b-2 md:border-b-0 md:p-0 p-4'>
                        <div className='p-4 md:bg-purple-900 md:bg-opacity-45 rounded-full'>
                            <img src={'/moipayway-icon.png'} className='w-6' alt='icon' />
                        </div>
                        <div className='flex flex-col items-end text-[11px] md:text-sm pr-2'>
                            <div className='font-normal'>{info?.merchant.name}</div>
                            <div className='font-bold md:font-normal'>{info?.user.email}</div>
                            <div className='font-bold'>{info?.currency.main.toUpperCase()} {numberFormat(info ? info.amount.main : "")}</div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center w-full pb-[100px] bg-no-repeat overflow-hidden bg-green-500 md:bg-inherit bg-right-top md:bg-left-top' style={{ backgroundImage: "url(/money-illus.svg)", backgroundSize: "450px" }}>
                    <div className='w-full md:w-[400px] pt-[50px] px-8 md:px-0'>
                        <div className='mb-5 p-4 rounded-full bg-neutral-100 inline-block text-purple-900 text-opacity-80'>
                            <GiPayMoney className='w-10 h-10' />
                        </div>
                        <div className='text-2xl mb-8 text-left font-bold'>How would <br /> You like to pay ?</div>
                        <div className='hidden md:grid md:grid-cols-3 gap-1 w-full'>
                            {info && info.payment_method.available.includes("card_embed") &&
                                <div className='text-center cursor-pointer p-2 bg-neutral-50 bg-opacity-90 shadow-md rounded-lg' onClick={() => navigate(`/hosted/${info?.order_reference_code}/card-embed`)}>
                                    <div className='mb-1 underline-offset-2'>Card</div>
                                    <div className='bg-purple-900 p-4 bg-opacity-90 rounded-full inline-block'>
                                        <FaCreditCard className='text-white w-4 h-4' />
                                    </div>

                                </div>
                            }

                            {info && info.payment_method.available.includes("dynamic_virtual_account") &&
                                <div className='text-center cursor-pointer p-2 bg-neutral-50 bg-opacity-90 shadow-md rounded-lg' onClick={() => navigate(`/hosted/${info?.order_reference_code}/bank-transfer`)}>
                                    <div className='mb-1 underline-offset-2'>Bank Transfer</div>
                                    <div className='bg-purple-900 p-4 bg-opacity-90 rounded-full inline-block'>
                                        <GrTransaction className='text-white w-4 h-4' />
                                    </div>
                                </div>
                            }

                            {info && info.payment_method.available.includes("ussd") &&
                                <div className='text-center cursor-pointer p-2 bg-neutral-50 bg-opacity-90 shadow-md rounded-lg' onClick={() => navigate(`/hosted/${info?.order_reference_code}/ussd`)}>
                                    <div className='mb-1 underline-offset-2'>USSD</div>
                                    <div className='bg-purple-900 p-4 bg-opacity-90 rounded-full inline-block'>
                                        <FaSlackHash className='text-white w-4 h-4' />
                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='block md:hidden'>
                    <MobileMenu />
                </div>
            </div>
        </>
    )
}

export default Home